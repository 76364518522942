const header = document.querySelector(".header");

if (header) {
  window.addEventListener("scroll", () => {
    const scrollY = window.scrollY;

    if (scrollY > 100) {
      header.style.display = "block";
    }
  });

  window.addEventListener("scrollend", () => {
    if (window.scrollY <= 100) {
      header.style.display = "none";
    }
  });
}
